import React, { Component } from 'react'
import ReactFullpage from '@fullpage/react-fullpage'
import { Link, graphql } from 'gatsby'
import SEO from '../components/seo'
import Nav from '../components/nav'
import ExploreIcon from '../components/explore'
import MapImage from '../images/map.svg'

class Location extends Component {
  constructor(props) {
    super(props)
    this.state = {
      backgroundDark : true
    }
  }

  onLeave(origin, destination, direction) {
    if (destination.index === 1) {
      this.setState({ backgroundDark: false })
    }
    else {
      this.setState({ backgroundDark: true })
    }
  }

  render() {
    const data = this.props.data
    
    return (
      <>
        <SEO title="Location" />
        <Nav logoFill={`${this.state.backgroundDark ? 'light' : 'dark' }`}>
          <div className="col text-left">
            <Link className={`target ${this.state.backgroundDark ? 'fill-light' : 'fill-dark' }`} to="/explore" style={{height:"1.875rem"}}><ExploreIcon /></Link>
          </div>
        </Nav>
        <ReactFullpage
          // fullpage options
          licenseKey={'C1912978-A39F4CE4-9F6AC28B-DFB573DA'}
          anchors={['exchange106', 'trx', 'mrt']}
          navigation
          slidesNavigation
          navigationTooltips={['Exchange 106', 'TRX', 'MRT']}
          sectionsColor={['#002856', '#e8e7e3', '#002856']}
          lazyLoading
          onLeave={this.onLeave.bind(this)}

          render={() => (
            <ReactFullpage.Wrapper>
              <section className="section" key="section0" id="section0" style={{ backgroundImage: `url(${data.section0.childImageSharp.fluid.src})` }}>
                <div className="container overlay bg-dark col-lg-5 offset-lg-7 col-md-8 offset-md-4 col-11 offset-1 mr-0 pl-3 pr-5 py-4 p-md-5">
                  <h2 className="title">
                    The Location
                  </h2>
                  <div className="py-3">
                    <p>The once familiar skyline now has a formidable new presence - a building so magnificent it demands a pause.</p>
              
                    <p>Looking closer, evidence of a new Malaysia surrounds the building. A visionary city within a city - is writing the next chapter in the country's global development.</p>
                    
                    <p className="mb-0">And if the world-beating facilities provide the punctuation, Exchange 106 is the full stop.</p>
                  </div>
                </div>
              </section>

              <section className="section" key="section1" id="section1">
                <div className="container p-5">
                  <div className="row">
                    <div className="col-lg-6 mt-lg-0 mx-lg-0 mx-md-5 p-lg-0 py-md-5 p-3">
                      <img width="auto" height="auto" src={MapImage} alt="Exchange 106 location" style={{ maxHeight:"37.5rem" }} />
                    </div>
                    <div className="col-lg-6 my-lg-auto pl-lg-5 pr-lg-0">
                      <h2 className="title">
                        A Building at the<br/>
                        Heart of the Action
                      </h2>
                      <div className="py-md-3">
                        <p className="mb-0">Exchange 106 is at the core of Tun Razak Exchange (TRX), a city within a city. It is a truly holistic urban landscape, a natural extension to the Golden Triangle – the city’s commercial, entertainment and shopping belt. It is a platform of connections and a master development of accessibility.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="section" key="section2" id="section2" style={{ backgroundImage: `url(${data.section2.childImageSharp.fluid.src})` }}>
                <div className="container overlay bg-dark col-lg-5 offset-lg-7 col-md-8 offset-md-4 col-11 offset-1 mr-0 pl-3 pr-5 py-4 p-md-5">
                  <h2 className="title">
                    MRT Station<br/>
                    Tun Razak Exchange (TRX)
                  </h2>
                  <div className="py-3">
                    <p className="mb-0">This is the largest underground station of the SBK Line. Tun Razak Exchange Station is an interchange station between the SBK Line and the future SSP Line. Forms, patterns and interior finishes within the station feature a modern contextual interpretation of an Islamic corporate theme.</p>
                  </div>
                </div>
              </section>
            </ReactFullpage.Wrapper>
          )}
        />
      </>
    )
  }
}

export default Location

export const query = graphql`
  query {
    section0: file(name: {eq: "location-section0"}) {
      id
      name
      childImageSharp {
        fluid(quality: 100, maxHeight: 1080) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      relativeDirectory
    }
    section2: file(name: {eq: "location-section2"}) {
      id
      name
      childImageSharp {
        fluid(quality: 100, maxHeight: 1080) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      relativeDirectory
    }
  }
`